import styled from "styled-components";
import { FaGithub } from "react-icons/fa";
import { SiApacheairflow, SiApachecassandra, SiBootstrap, SiBun, SiClickhouse, SiCsharp, SiCss3, SiDocker, SiGit, SiGo, SiHtml5, SiJavascript, SiKonva, SiKubernetes, SiMongodb, SiMui, SiMysql, SiPwa, SiPython, SiReact, SiRust, SiThreedotjs, SiTimescale, SiTypescript, SiUnity, SiVite, SiVuedotjs } from "react-icons/si";

import LinksList from "./LinksList";
import { breakpoints } from "../breakpoints";

function Main() {
    const icons = [
        [<SiTypescript />, 'typescript icon'],
        [<SiJavascript />, 'javascript icon'],
        [<SiCsharp />, 'c sharp icon'],
        [<SiPython />, 'python icon'],
        [<SiRust />, 'rust icon'],
        [<SiGo />, 'go icon'],
        [<SiHtml5 />, 'html5 icon'],
        [<SiCss3 />, 'css3 icon'],
        [<SiUnity />, 'unity framework icon'],
        [<SiVuedotjs />, 'vue icon'],
        [<SiReact />, 'react icon'],
        [<SiKonva />, 'konva icon'],
        [<SiBootstrap />, 'bootstrap icon'],
        [<SiMui />, 'mui icon'],
        [<SiThreedotjs />, 'three.js icon'],
        [<SiVite />, 'vite icon'],
        [<SiBun />, 'bun icon'],
        [<SiDocker />, 'docker icon'],
        [<SiKubernetes />, 'kubernetes icon'],
        [<SiGit />, 'git icon'],
        [<SiApachecassandra />, 'cassandra icon'],
        [<SiTimescale />, 'timescale icon'],
        [<SiClickhouse />, 'clickhouse icon'],
        [<SiApacheairflow />, 'airflow icon'],
        [<SiMysql />, 'mysql icon'],
        [<SiMongodb />, 'mongodb icon'],
        [<SiPwa />, 'pwa icon'],
    ]

    return (
        <StyledMain>
            <section>
                <article>
                    <h2>
                        About Me
                    </h2>
                    <p> I'm a fullstack developer with a knack for turning wild ideas into working web apps. My aim is to use my technical and organizational skills to bring unique visions to life.  <strong>I just love coding to make things happen</strong>. </p>
                    <p> I started playing around with JavaScript, HTML, and CSS back in 2004 as a fun hobby, and it's been a journey ever since. Along the way, I've picked up backend development and data engineering, and dipped my toe in game development, always eager to learn and take on new challenges. </p>
                    <StyledUL>
                        {
                            icons.map(([icon, description], i) => (
                                <li key={"icon" - i}>
                                    <span aria-hidden="true">{icon}</span>
                                    <span className="accessibility-only">{description}</span>
                                </li>
                            ))
                        }
                    </StyledUL>
                </article>
                <article>
                    <h2>
                        Projects
                    </h2>
                    <p>
                        Some fun ideas and weekend projects.
                    </p>
                    <section>
                        <ProjectsArticle>
                            <h3>
                                Proofs of Concept
                            </h3>
                            <LinksList
                                elements={[
                                    [
                                        <a href="https://react-cylinder.onrender.com" target="_blank" rel="noopener noreferrer">Cylindrical elements</a>,
                                        'a react component to wrap an html element around a cylinder',
                                        <a href="https://github.com/impproductions/react-cylinder" className="icon" target="_blank" rel="noopener noreferrer"><FaGithub /></a>
                                    ],
                                    [
                                        <a href="https://react-nested-squares.onrender.com" target="_blank" rel="noopener noreferrer">Nested squares</a>,
                                        'a geometric visual exercise',
                                        <a href="https://github.com/impproductions/react-nested-squares" className="icon" target="_blank" rel="noopener noreferrer"><FaGithub /></a>
                                    ],
                                    [
                                        <a href="https://react-cube.onrender.com" target="_blank" rel="noopener noreferrer">Cubic Website</a>,
                                        'a dummy website built on the six faces of a rotating cube',
                                        <a href="https://github.com/impproductions/react-cube" className="icon" target="_blank" rel="noopener noreferrer"><FaGithub /></a>
                                    ],
                                ]}
                            ></LinksList>
                        </ProjectsArticle>
                        <ProjectsArticle>
                            <h3>
                                Weekend Projects
                            </h3>
                            <LinksList
                                elements={[
                                    [
                                        <a href="https://resumark.impproductions.org" target="_blank" rel="noopener noreferrer">ResuMark</a>,
                                        'A markdown editor with live preview and customizable templates, in React',
                                        <a href="https://github.com/impproductions/resumark" className="icon" target="_blank" rel="noopener noreferrer"><FaGithub /></a>
                                    ],
                                    [
                                        <a href="https://beamulator.impproductions.org" target="_blank" rel="noopener noreferrer">Beamulator</a>,
                                        'Agent-based user population simulator for automated testing, in Elixir',
                                        <a href="https://github.com/impproductions/beamulator" className="icon" target="_blank" rel="noopener noreferrer"><FaGithub /></a>
                                    ],
                                ]}
                            ></LinksList>
                        </ProjectsArticle>
                    </section>
                </article>
            </section>
        </StyledMain>
    )
}

const StyledMain = styled.main`
    height: 100%;
    border-left: 1px solid var(--primary-color);
    border-top: 1px solid var(--primary-color);
    background-color: var(--surface-1);

    article {
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    
    article h2 {
        padding-left: 0.5rem;
    }
    article h3 {
    }

    article p {
        padding-left: 0.5rem;
    }
`;

const ProjectsArticle = styled.article`

`;
const StyledUL = styled.ul`
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    list-style: none;
    flex-wrap: wrap;
    padding-left: 1rem;
    padding-right: 1rem;
    gap: 0.5rem;

    li {
        flex: 0 0;
    }
    
    @media screen and (${breakpoints.mobile}){
        li {
            flex: 0 0 15%;
        }
    }
`;

export default Main;